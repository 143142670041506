// src/components/Tenant.tsx
import React from 'react';
import './Tenant.css';

interface TenantProps {
  tenantName: string;
}

const Tenant: React.FC<TenantProps> = ({ tenantName }) => {
  return (
    <div className="tenant-container">
      <aside className="tenant-sidebar">
        <div className="sidebar-header">AppToolbox</div>
        <div className="sidebar-section">
          <div className="sidebar-title">Dashboards</div>
          <ul>
            <li>My Dashboard</li>
            <li>Org Dashboards</li>
            <li>Area Dashboards</li>
          </ul>
        </div>
        <div className="sidebar-section">
          <div className="sidebar-title">Business Objectives</div>
          <ul>
            <li>Initiatives</li>
            <li>Resource Planning</li>
            <li>Tasks</li>
            <li>Deliverables</li>
            <li>Reports</li>
          </ul>
        </div>
        <div className="sidebar-section">
          <div className="sidebar-title">Business Modeling</div>
          <ul>
            <li>Value Streams</li>
            <li>Service Areas</li>
            <li>Organization</li>
            <li>Product Groups</li>
            <li>Products</li>
            <li>Addresses</li>
            <li>Sites</li>
            <li>Vendors</li>
          </ul>
        </div>
        <div className="sidebar-section">
          <div className="sidebar-title">Applications</div>
          <ul>
            <li>Application Catalog</li>
            <li>Application Standards</li>
            <li>Applications Implemented</li>
            <li>Applications Where Used</li>
            <li>Ecosystem Visualization</li>
          </ul>
        </div>
        <div className="sidebar-section">
          <div className="sidebar-title">Teams</div>
          <ul>
            <li>Teams</li>
            <li>Data</li>
          </ul>
        </div>
      </aside>
      <main className="tenant-main">
        <header className="tenant-header">
          <div className="search-box">Search Box</div>
          <div className="breadcrumb">Breadcrumb / ... /</div>
          <div className="page-header">Page Header</div>
        </header>
        <div className="tenant-content">
          <p>Page content for tenant: {tenantName}</p>
        </div>
      </main>
    </div>
  );
};

export default Tenant;
