// src/routes/TenantRoute.tsx
import React from 'react';
import { useParams } from 'react-router-dom';
import Tenant from '../components/Tenant';

const TenantRoute: React.FC = () => {
  const { tenantName } = useParams<{ tenantName: string }>();

  // Extract the tenant from the subdomain if it's present
  const hostnameParts = window.location.hostname.split('.');
  const subdomainTenant = hostnameParts.length > 2 ? hostnameParts[0] : '';
  const tenant = tenantName || (subdomainTenant !== 'localhost' && subdomainTenant) || '';

  return <Tenant tenantName={tenant} />;
};

export default TenantRoute;
