// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainRoute from './routes/MainRoute';
import TenantRoute from './routes/TenantRoute';

const App: React.FC = () => {
  // Extract the subdomain
  const subdomainTenant = window.location.hostname.split('.')[0];
  const isSubdomain = subdomainTenant && subdomainTenant !== 'localhost';

  return (
    <Router>
      <Routes>
        {isSubdomain ? (
          <Route path="*" element={<TenantRoute />} />
        ) : (
          <>
            <Route path="/:tenantName" element={<TenantRoute />} />
            <Route path="/" element={<MainRoute />} />
          </>
        )}
      </Routes>
    </Router>
  );
};

export default App;
