// src/components/Main.tsx
import React from 'react';
import { AppBar, Box, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Menu as MenuIcon, Dashboard as DashboardIcon, BarChart as BarChartIcon, CalendarToday as CalendarIcon, Inbox as InboxIcon, Mail as MailIcon } from '@mui/icons-material';
import './Main.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const drawerWidth = 240;

const data = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'Mobile',
      data: [65, 59, 80, 81, 56, 55, 40],
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
    },
    {
      label: 'Desktop',
      data: [28, 48, 40, 19, 86, 27, 90],
      backgroundColor: 'rgba(153, 102, 255, 0.6)',
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Mobile / Desktop',
    },
  },
};

const Main: React.FC = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            AppToolBox
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {['Dashboard', 'Analytics', 'Calendar', 'Inbox', 'Mail'].map((text, index) => (
              <ListItem button key={text}>
                <ListItemIcon>
                  {index === 0 ? <DashboardIcon /> : index === 1 ? <BarChartIcon /> : index === 2 ? <CalendarIcon /> : index === 3 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {['Profile', 'Settings'].map((text, index) => (
              <ListItem button key={text}>
                <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <Toolbar />
        <Typography variant="h4" gutterBottom>
          Analytics Dashboard
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Welcome back, User! We've missed you. 🌞
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          <Box sx={{ width: '48%', minHeight: 200, bgcolor: '#f5f5f5', p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              Visitors
            </Typography>
            <Typography variant="h4">24,532</Typography>
            <Typography variant="subtitle2" color="textSecondary">
              +14% Since last week
            </Typography>
          </Box>
          <Box sx={{ width: '48%', minHeight: 200, bgcolor: '#f5f5f5', p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              Activity
            </Typography>
            <Typography variant="h4">63,200</Typography>
            <Typography variant="subtitle2" color="textSecondary">
              -12% Since last week
            </Typography>
          </Box>
          <Box sx={{ width: '48%', minHeight: 200, bgcolor: '#f5f5f5', p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              Real-Time
            </Typography>
            <Typography variant="h4">1,320</Typography>
            <Typography variant="subtitle2" color="textSecondary">
              -18% Since last week
            </Typography>
          </Box>
          <Box sx={{ width: '48%', minHeight: 200, bgcolor: '#f5f5f5', p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              Bounce
            </Typography>
            <Typography variant="h4">12,364</Typography>
            <Typography variant="subtitle2" color="textSecondary">
              +27% Since last week
            </Typography>
          </Box>
          <Box sx={{ width: '100%', minHeight: 400, bgcolor: '#f5f5f5', p: 2, mb: 2 }}>
            <Bar data={data} options={options} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Main;
